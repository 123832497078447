import { useCallback, useState } from 'react';

const SUBSCRIBE_HOST = import.meta.env.VITE_SUBSCRIBE_HOST;

export function useFetch(accountId, popupId, path) {
	const [loading, setLoading] = useState(false);
	const [result, setResult] = useState(null);
	const [error, setError] = useState(null);

	const onSubmit = useCallback(
		async (body = {}) => {
			setLoading(true);
			setResult(null);
			try {
				const result = await fetch(`${SUBSCRIBE_HOST}/public/${accountId}${path}`, {
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json'
					},
					mode: 'cors',
					credentials: 'omit',
					cache: 'no-cache',
					body: JSON.stringify({
						popup_id: popupId,
						...body
					})
				});
				if (result.ok) {
					setResult(result);
				} else {
					const body = await result.text();
					setError(body);
				}
			} catch (e) {
				setError(e.message);
			} finally {
				setLoading(false);
			}
		},
		[accountId, path, popupId]
	);

	return {
		submit: onSubmit,
		result,
		isLoading: loading,
		error
	};
}
