import { useEffect, useState } from 'react';

function isCurrentlyMobile() {
	const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
	return width < 768;
}

export function useIsMobile() {
	const [isMobile, setIsMobile] = useState(isCurrentlyMobile);

	useEffect(() => {
		function checkMobile() {
			setIsMobile(isCurrentlyMobile());
		}

		window.addEventListener('resize', checkMobile);

		checkMobile();

		return () => window.removeEventListener('resize', checkMobile);
	}, []);

	return isMobile;
}
