import { useCallback, useEffect, useState } from 'react';

function getScrollPercentage() {
	const yScroll = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop || 0;
	const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0;
	const documentHeight = Math.max(
		document.body.scrollHeight || 0,
		document.documentElement.scrollHeight || 0,
		document.body.offsetHeight || 0,
		document.documentElement.offsetHeight || 0,
		document.body.clientHeight || 0,
		document.documentElement.clientHeight || 0
	);
	return ((yScroll + windowHeight) / documentHeight) * 100;
}

export function useScrollReached(percentage) {
	const isPercentageReached = useCallback(() => typeof percentage === 'number' && getScrollPercentage() >= percentage, [percentage]);

	const [positionReached, setPositionReached] = useState(isPercentageReached);

	useEffect(() => {
		setPositionReached(isPercentageReached());
	}, [isPercentageReached]);

	useEffect(() => {
		function onScroll() {
			if (isPercentageReached()) {
				setPositionReached(true);
			}
		}

		if (!positionReached) {
			document.addEventListener('scroll', onScroll);
		}

		return () => document.removeEventListener('scroll', onScroll);
	}, [isPercentageReached, positionReached]);

	return positionReached;
}
