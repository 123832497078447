import { useFetch } from './useFetch';

export function useSubscribe(accountId, popupId, locale) {
	const { submit, isLoading, result, error } = useFetch(accountId, popupId, '/subscribe');

	function onSubscribe(email, phone, firstName, lastName, country, tags, birthdate, captcha, secondarySubmission) {
		submit({
			email,
			phone,
			first_name: firstName,
			last_name: lastName,
			country,
			tags,
			birthdate,
			captcha,
			locale,
			secondary_submission: secondarySubmission
		});
	}

	return {
		subscribe: onSubscribe,
		isSubscribed: Boolean(result),
		isLoading,
		error
	};
}
