import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'regenerator-runtime/runtime';
import { StyleSheetManager } from 'styled-components';
import ActivePopup from './ActivePopup';
import App, { isActiveDate, isTargetedLanguage } from './App';
import Account from './account';
import Customer from './customer';

const SEGUNO_CDN_URL = import.meta.env.VITE_CDN_URL;
const SEGUNO_PREFIX = '__seguno-';
const SEGUNO_POPUP_CONFIG_EVENT = `${SEGUNO_PREFIX}POPUP-CONFIG`;
const SEGUNO_EMBED_DATA_ATTR = 'data-seguno-popup-embed';

const customer = new Customer();

function getEmbeddedPopupElements() {
	return Array.from(document.querySelectorAll(`[${SEGUNO_EMBED_DATA_ATTR}]`)).filter(element =>
		Boolean(element.getAttribute(SEGUNO_EMBED_DATA_ATTR))
	);
}

(function () {
	const _shadows = new WeakMap();

	function getOrCreateShadow(element) {
		if (!_shadows.has(element)) {
			const attachShadowFunc = typeof element._attachShadow === 'function' ? '_attachShadow' : 'attachShadow';
			_shadows.set(element, element[attachShadowFunc]({ mode: 'closed' }));
		}
		return _shadows.get(element);
	}

	class PopupComponent extends HTMLElement {
		connectedCallback() {
			const mountPoint = document.createElement('div');
			const shadowRoot = getOrCreateShadow(this);
			shadowRoot.replaceChildren();
			shadowRoot.appendChild(mountPoint);
			const accountId = this.getAttribute('data-account');

			function listener(event) {
				document.removeEventListener(SEGUNO_POPUP_CONFIG_EVENT, listener);

				const embeddedIds = getEmbeddedPopupElements().map(embed => embed.getAttribute(SEGUNO_EMBED_DATA_ATTR));

				// Filter out popups that are supposed to be embedded
				const popups = event.detail.popups.filter(popup => !embeddedIds.includes(popup.id));

				const root = createRoot(mountPoint);
				root.render(
					<StrictMode>
						<StyleSheetManager target={shadowRoot}>
							<App accountId={accountId} popups={popups} customer={customer} />
						</StyleSheetManager>
					</StrictMode>
				);
			}

			document.addEventListener(SEGUNO_POPUP_CONFIG_EVENT, listener);
		}
	}
	customElements.define('seguno-popup', PopupComponent);

	class EmbeddedPopupComponent extends HTMLElement {
		connectedCallback() {
			const mountPoint = document.createElement('div');
			const shadowRoot = getOrCreateShadow(this);
			shadowRoot.appendChild(mountPoint);
			const accountId = this.getAttribute('data-account');
			const popupId = this.getAttribute('data-popup');

			function listener(event) {
				document.removeEventListener(SEGUNO_POPUP_CONFIG_EVENT, listener);

				// Find the popup that's supposed to be embedded
				const popup = event.detail.popups.filter(popup => Boolean(popup.embedded)).find(popup => popupId === popup.id);

				if (!popup || !isActiveDate(popup) || !isTargetedLanguage(popup)) {
					return;
				}

				function noOp() {}

				const root = createRoot(mountPoint);
				root.render(
					<StrictMode>
						<StyleSheetManager target={shadowRoot}>
							<ActivePopup
								accountId={accountId}
								popup={popup}
								customer={customer}
								onOpenPopup={noOp}
								onClosePopup={noOp}
								activePopupId={popup.id}
								embedded
							/>
						</StyleSheetManager>
					</StrictMode>
				);
			}

			document.addEventListener(SEGUNO_POPUP_CONFIG_EVENT, listener);
		}
	}
	customElements.define('seguno-embedded-popup', EmbeddedPopupComponent);
})();

(() => {
	const account = new Account();
	if (!account.isAccountAvailable()) {
		return;
	}
	const rootElement = document.createElement('seguno-popup');
	rootElement.setAttribute('data-account', account.getAccountId());
	rootElement.style.position = 'fixed';
	rootElement.style.top = '-5000px';
	rootElement.style.left = '-5000px';
	rootElement.style.width = '0px';
	rootElement.style.height = '0px';
	rootElement.style.minHeight = '0px';
	rootElement.style.minWidth = '0px';
	rootElement.style.zIndex = '2147483600';
	rootElement.style.display = 'block';
	rootElement.className = `${SEGUNO_PREFIX}popup-wrapper needsclick`;
	document.body.appendChild(rootElement);
	const style = document.createElement('style');
	document.head.appendChild(style);
	const css = '.__seguno-disable-scroll { height: 100%; overflow: hidden }';
	style.appendChild(document.createTextNode(css));

	getEmbeddedPopupElements().forEach(embedContainer => {
		const embeddedPopup = document.createElement('seguno-embedded-popup');
		embeddedPopup.setAttribute('data-account', account.getAccountId());
		embeddedPopup.setAttribute('data-popup', embedContainer.getAttribute(SEGUNO_EMBED_DATA_ATTR));
		embeddedPopup.className = `${SEGUNO_PREFIX}popup-wrapper needsclick`;
		embeddedPopup.style.display = 'block';
		embedContainer.appendChild(embeddedPopup);
	});

	const scriptTag = document.createElement('script');
	scriptTag.src = `${SEGUNO_CDN_URL}${account.getAccountId()}/popups.js`;
	scriptTag.async = true;
	document.body.appendChild(scriptTag);
})();
