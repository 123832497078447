import { useCallback } from 'react';

export function useTriggerEvent(element, eventName) {
	const triggerEvent = useCallback(
		data => {
			if (!element) {
				return;
			}
			try {
				element.dispatchEvent(new CustomEvent(eventName, { bubbles: true, composed: true, detail: data }));
			} catch (e) {
				console.error('Error triggering event: ', e.message);
			}
		},
		[eventName, element]
	);
	return triggerEvent;
}
