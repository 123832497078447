import Storage from './storage';

function getLocation() {
	return window.location.href;
}

const getParameterByName = name => {
	const url = getLocation();
	name = name.replace(/[[\]]/g, '\\$&');
	const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
	const results = regex.exec(url);
	if (!results) {
		return null;
	}
	if (!results[2]) {
		return '';
	}
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const CUSTOMER_LAST_EMAIL_SUBSCRIBED_KEY = 'seguno_last_sub';
const CUSTOMER_LAST_PHONE_SUBSCRIBED_KEY = 'seguno_last_sms_sub';
const CUSTOMER_PAGE_VIEWS_KEY = 'seguno_view_count';
const MILLIS_PER_SECOND = 1000;
const MILLIS_PER_MINUTE = MILLIS_PER_SECOND * 60;
const MILLIS_PER_HOUR = MILLIS_PER_MINUTE * 60;
const MILLIS_PER_DAY = MILLIS_PER_HOUR * 24;
const DAYS_TO_EXPIRE = 90;
const MILLIS_TO_EXPIRE = DAYS_TO_EXPIRE * MILLIS_PER_DAY;

export default class Customer {
	constructor() {
		this.storage = new Storage();
		if (getParameterByName('utm_source') === 'seguno') {
			this.setCustomerEmailSubscribed();
		}
		const customerSubscribedDate = this.getEarliestCustomerSubscribedDate();
		const now = new Date();
		if (customerSubscribedDate && now.getTime() - customerSubscribedDate.getTime() >= MILLIS_TO_EXPIRE) {
			this.expireCustomerSubscribed();
		}
		this.customerEmailSubscribed = this.getCustomerEmailSubscribedDate() !== null || window.location.pathname === '/challenge';
		this.customerPhoneSubscribed = this.getCustomerPhoneSubscribedDate() !== null;
		this.customerJustSubscribed = getParameterByName('customer_posted') === 'true';
		if (this.isCustomerJustSubscribed()) {
			this.setCustomerEmailSubscribed();
		}
		this.incrementViewCount();
	}

	getEarliestCustomerSubscribedDate() {
		const emailSubscribedDate = this.getCustomerEmailSubscribedDate();
		const phoneSubscribedDate = this.getCustomerPhoneSubscribedDate();
		if (emailSubscribedDate && !phoneSubscribedDate) {
			return emailSubscribedDate;
		} else if (!emailSubscribedDate && phoneSubscribedDate) {
			return phoneSubscribedDate;
		} else if (emailSubscribedDate && phoneSubscribedDate) {
			return emailSubscribedDate.getTime() < phoneSubscribedDate.getTime() ? emailSubscribedDate : phoneSubscribedDate;
		}
		return null;
	}

	getCustomerEmailSubscribedDate() {
		return this.storage.hasItem(CUSTOMER_LAST_EMAIL_SUBSCRIBED_KEY)
			? new Date(Number(this.storage.getItem(CUSTOMER_LAST_EMAIL_SUBSCRIBED_KEY)))
			: null;
	}

	getCustomerPhoneSubscribedDate() {
		return this.storage.hasItem(CUSTOMER_LAST_PHONE_SUBSCRIBED_KEY)
			? new Date(Number(this.storage.getItem(CUSTOMER_LAST_PHONE_SUBSCRIBED_KEY)))
			: null;
	}

	setCustomerEmailSubscribed() {
		this.storage.setItem(CUSTOMER_LAST_EMAIL_SUBSCRIBED_KEY, new Date().getTime().toString());
	}

	setCustomerPhoneSubscribed() {
		this.storage.setItem(CUSTOMER_LAST_PHONE_SUBSCRIBED_KEY, new Date().getTime().toString());
	}

	expireCustomerSubscribed() {
		this.storage.removeItem(CUSTOMER_LAST_EMAIL_SUBSCRIBED_KEY);
		this.storage.removeItem(CUSTOMER_LAST_PHONE_SUBSCRIBED_KEY);
	}

	isCustomerJustSubscribed() {
		return this.customerJustSubscribed;
	}

	isCustomerEmailSubscribed() {
		return this.customerEmailSubscribed;
	}

	isCustomerPhoneSubscribed() {
		return this.customerPhoneSubscribed;
	}

	incrementViewCount() {
		this.storage.setItem(CUSTOMER_PAGE_VIEWS_KEY, this.getViewCount() + 1);
	}

	getViewCount() {
		return Number(this.storage.getItem(CUSTOMER_PAGE_VIEWS_KEY) || '0');
	}

	resetViewCount() {
		this.storage.removeItem(CUSTOMER_PAGE_VIEWS_KEY);
	}
}
