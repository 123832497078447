import { ANIMATIONS, DEFAULT_ANIMATION, Popup, POPUP_TRIGGER_TYPE, useIntersectionObserver } from '@segunosoftware/popup-shared';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useLinkRedirect } from './hooks/useLinkRedirect';
import { useScrollReached } from './hooks/useScrollReached';
import { useSubscribe } from './hooks/useSubscribe';
import { useTriggerEvent } from './hooks/useTriggerEvent';
import { useView } from './hooks/useView';

const APP_ID = import.meta.env.VITE_APP_ID;
const EVENT_PREFIX = 'seguno::popup::';

function arrayEquals(arr1 = [], arr2 = []) {
	if (arr1.length !== arr2.length) {
		return false;
	}
	const arr1Set = new Set(arr1);
	const arr2Set = new Set(arr2);
	for (const arr1Val of arr1Set) {
		if (!arr2Set.has(arr1Val)) {
			return false;
		}
	}
	return true;
}

ActivePopup.propTypes = {
	accountId: PropTypes.string.isRequired,
	popup: PropTypes.shape({
		id: PropTypes.string.isRequired,
		config: PropTypes.shape({
			theme: PropTypes.shape({
				disableScrolling: PropTypes.bool,
				animation: PropTypes.oneOf(Object.keys(ANIMATIONS))
			}).isRequired
		}).isRequired,
		triggerConfig: PropTypes.shape({
			triggerType: PropTypes.oneOf(Object.keys(POPUP_TRIGGER_TYPE)).isRequired,
			scrollPercentage: PropTypes.number.isRequired,
			delay: PropTypes.number.isRequired,
			activeEndDate: PropTypes.number
		}),
		brandingHideable: PropTypes.bool.isRequired,
		phonePermissionRequired: PropTypes.bool
	}).isRequired,
	onOpenPopup: PropTypes.func.isRequired,
	onClosePopup: PropTypes.func.isRequired,
	activePopupId: PropTypes.string,
	locale: PropTypes.string,
	embedded: PropTypes.bool
};

export default function ActivePopup({ accountId, popup, onOpenPopup, onClosePopup, activePopupId, customer, locale, embedded = false }) {
	const [popupRef, setPopupRef] = useState();

	const [stage, setStage] = useState(0);
	const [delayCompleted, setDelayCompleted] = useState(false);
	const [rendered, setRendered] = useState(false);
	const [lastSubmission, setLastSubmission] = useState(null);
	const [attemptedSubmission, setAttemptedSubmission] = useState(null);

	const [redirectAction, setRedirectAction] = useState(null);

	const scrollReached = useScrollReached(popup.triggerConfig.scrollPercentage);
	const { subscribe, isLoading, isSubscribed } = useSubscribe(accountId, popup.id, locale);
	const { view: submitImpression } = useView(accountId, popup.id);
	const { recordLinkRedirect, isLinkRedirectRecorded, isLoading: isLinkRedirectLoading } = useLinkRedirect(accountId, popup.id);

	const triggerSubscribe = useTriggerEvent(popupRef, `${EVENT_PREFIX}subscribe`);
	const triggerImpression = useTriggerEvent(popupRef, `${EVENT_PREFIX}impression`);
	const triggerClose = useTriggerEvent(popupRef, `${EVENT_PREFIX}close`);

	const isVisible = useIntersectionObserver(popupRef);

	const open = activePopupId === popup.id;
	const animation = popup.config.theme.animation ?? DEFAULT_ANIMATION;

	useEffect(() => {
		if (popup) {
			// Preload the images in all stages and the theme background image
			function preloadImage(image) {
				if (!image) {
					return;
				}
				const linkPreload = document.createElement('link');
				linkPreload.setAttribute('rel', 'preload');
				linkPreload.setAttribute('as', 'image');
				linkPreload.setAttribute('href', image);
				document.head.appendChild(linkPreload);
			}

			popup.config.stages
				.map(stage => stage.sections)
				.flat()
				.filter(section => !section.hidden)
				.map(section => [section.settings.backgroundImageUrl, section.settings.imageUrl])
				.flat()
				.forEach(preloadImage);

			preloadImage(popup.config.theme.backgroundImageUrl);

			const delayTimeout = setTimeout(() => setDelayCompleted(true), popup.triggerConfig.delay);
			return () => clearTimeout(delayTimeout);
		}
	}, [popup]);

	useEffect(() => {
		if (embedded) {
			setRendered(true);
		}
		if (!delayCompleted || activePopupId) {
			return;
		}
		if (
			popup.triggerConfig.triggerType === POPUP_TRIGGER_TYPE.LOAD ||
			(popup.triggerConfig.triggerType === POPUP_TRIGGER_TYPE.SCROLL && scrollReached)
		) {
			setRendered(true);
		} else if (popup.triggerConfig.triggerType === POPUP_TRIGGER_TYPE.EXIT) {
			function onCheckExitIntent(e) {
				if (!e.relatedTarget && e.clientY <= 0) {
					document.removeEventListener('mouseout', onCheckExitIntent);
					setRendered(true);
				}
			}
			document.addEventListener('mouseout', onCheckExitIntent);
			return () => document.removeEventListener('mouseout', onCheckExitIntent);
		}
	}, [popup, embedded, delayCompleted, scrollReached, activePopupId]);

	useEffect(() => {
		if (rendered) {
			// This is to guarantee the animation occurs after the render
			// has been committed to the DOM
			const timeout = setTimeout(() => onOpenPopup(popup.id), 0);
			return () => clearTimeout(timeout);
		}
	}, [onOpenPopup, popup.id, rendered]);

	useEffect(() => {
		if ((open && !embedded) || (isVisible && embedded)) {
			submitImpression();
			setLastSubmission(null);
			triggerImpression({ popup: popup.id });
		}
	}, [open, submitImpression, triggerImpression, popup.id, embedded, isVisible]);

	useEffect(() => {
		if (popup.config.theme.overlayHidden || !popup.config.theme.disableScrolling || embedded) {
			return;
		}
		if (open) {
			document.body.classList.add('__seguno-disable-scroll');
		} else {
			document.body.classList.remove('__seguno-disable-scroll');
		}
	}, [open, popup.config.theme.overlayHidden, popup.config.theme.disableScrolling, embedded]);

	useEffect(() => {
		if (isSubscribed) {
			if (attemptedSubmission.email) {
				customer.setCustomerEmailSubscribed();
			}
			if (attemptedSubmission.phone) {
				customer.setCustomerPhoneSubscribed();
			}
			setLastSubmission(attemptedSubmission);
			setStage(stage => stage + 1);
		}
	}, [isSubscribed, customer, attemptedSubmission]);

	const onClose = useCallback(() => {
		onClosePopup(popup.id);
		triggerClose({ popup: popup.id });
		if (embedded) {
			// Skip on closing the popup if it's embedded
			return;
		}
		// Wait for the animation to complete before telling it to stop rendering
		setTimeout(() => {
			setRendered(false);
			setStage(0);
		}, ANIMATIONS[animation].out_duration);
	}, [animation, onClosePopup, popup.id, embedded, triggerClose]);

	useEffect(() => {
		if (isLinkRedirectRecorded && redirectAction) {
			onClose();
			setRedirectAction(null);
			if (!redirectAction.newWindow) {
				window.location.href = redirectAction.url;
			}
		}
	}, [isLinkRedirectRecorded, onClose, redirectAction]);

	function onRedirect(url, newWindow) {
		setRedirectAction({ url, newWindow });
		// If it's a new window, we need to redirect from the action otherwise
		// it'll be blocked by iOS
		if (newWindow) {
			window.open(url, '_blank');
		}
		recordLinkRedirect(Boolean(lastSubmission));
	}

	function onSubmit(email, phone, firstName, lastName, country, tags, birthdate, captcha) {
		if (isLoading) {
			return;
		}
		if (
			(email || phone) &&
			(!lastSubmission ||
				lastSubmission.email !== email ||
				lastSubmission.phone !== phone ||
				lastSubmission.firstName !== firstName ||
				lastSubmission.lastName !== lastName ||
				lastSubmission.country !== country ||
				!arrayEquals(lastSubmission.tags, tags) ||
				lastSubmission.birthdate !== birthdate)
		) {
			subscribe(email, phone, firstName, lastName, country, tags, birthdate, captcha, Boolean(lastSubmission));
			const submission = {
				email,
				phone,
				firstName,
				lastName,
				country,
				tags,
				birthdate
			};
			setAttemptedSubmission(submission);
			triggerSubscribe({ popup: popup.id, submission });
			triggerShopifyVisitorAPI(submission.email, submission.phone);
		} else {
			setStage(stage + 1);
		}
	}

	if (!rendered) {
		return null;
	}

	return (
		<Popup
			ref={setPopupRef}
			config={popup.config}
			open={open}
			onClose={onClose}
			onRedirect={onRedirect}
			stage={stage}
			loading={isLoading}
			redirecting={isLinkRedirectLoading}
			onSubmit={onSubmit}
			brandingHideable={popup.brandingHideable}
			phonePermissionRequired={popup.phonePermissionRequired}
			embedded={embedded}
			triggerConfig={popup.triggerConfig}
		/>
	);
}

function triggerShopifyVisitorAPI(email, phone) {
	try {
		if (typeof window.Shopify?.analytics?.visitor === 'function' && APP_ID) {
			const visitorInfo = {};
			if (email) {
				visitorInfo.email = email;
			}
			if (phone) {
				visitorInfo.phone = phone;
			}
			window.Shopify.analytics.visitor(visitorInfo, {
				appId: APP_ID
			});
		}
	} catch (e) {
		console.warn('Unable to trigger Shopify Visitor API');
	}
}
