import { useCallback } from 'react';
import { useFetch } from './useFetch';

const CRAWLER_USER_AGENTS = [
	'googlebot',
	'storebot-google',
	'adsbot-google',
	'mediapartners-google',
	'googleweblight',
	'duplexweb-google',
	'bingbot',
	'yahoo! slurp',
	'duckduckbot',
	'baiduspider',
	'yandexbot',
	'exabot',
	'facebot',
	'facebookexternalhit',
	'ia_archiver'
];

export function useView(accountId, popupId) {
	const { submit, isLoading, result, error } = useFetch(accountId, popupId, '/view');

	const userAgent = (window?.navigator?.userAgent ?? '').toLowerCase();

	const onSubmit = useCallback(
		(...args) => {
			if (CRAWLER_USER_AGENTS.some(agent => userAgent.includes(agent))) {
				return;
			}
			submit(...args);
		},
		[userAgent, submit]
	);

	return {
		view: onSubmit,
		isViewed: Boolean(result),
		isLoading,
		error
	};
}
