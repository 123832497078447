const PROPERTY_SHOP_ID = 'seguno:shop-id';
const PROPERTY_DEBUG = 'seguno:debug';

function getMetaProperty(propertyName) {
	const metaTag = document.querySelector(`meta[property='${propertyName}']`);
	return metaTag ? metaTag.getAttribute('content') : undefined;
}

export default class Account {
	constructor() {
		const accountId = getMetaProperty(PROPERTY_SHOP_ID);
		if (accountId) {
			this.accountId = accountId;
			this.debugEnabled = getMetaProperty(PROPERTY_DEBUG) === 'true';
		}
	}

	isAccountAvailable() {
		return this.accountId !== undefined && this.accountId !== null;
	}

	isDebugEnabled() {
		return this.debugEnabled;
	}

	getAccountId() {
		return this.accountId;
	}
}
