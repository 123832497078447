import { useFetch } from './useFetch';

export function useLinkRedirect(accountId, popupId) {
	const { submit, isLoading, result, error } = useFetch(accountId, popupId, '/link-redirect');

	function onRedirect(secondarySubmission) {
		submit({ secondary_submission: secondarySubmission });
	}

	return {
		recordLinkRedirect: onRedirect,
		isLinkRedirectRecorded: Boolean(result),
		isLoading,
		error
	};
}
